import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { TextField, Grid, Box, Typography, Container, Paper, IconButton, Collapse, Button } from '@mui/material';
import { Add, ExpandMore, ExpandLess, Delete } from '@mui/icons-material';
import dayjs from "dayjs";

// Register scales and chart elements
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Helper function to generate labels based on start and end date
const generateLabels = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const diff = end.diff(start, "month");

  return Array.from({ length: diff + 1 }, (_, i) => start.add(i, 'month').format('MMM YYYY'));
};

// Custom plugin to draw color-coded regions on the Y-axis
const regionPlugin = {
  id: 'regionPlugin',
  beforeDatasetsDraw: (chart) => {
    const { ctx, chartArea: { top, bottom, left, right }, scales: { y } } = chart;

    const regions = [
      { min: 0, max: 16, color: "rgba(0, 0, 0, 0.1)", label: "Black (<16)" }, // Black
      { min: 16, max: 20, color: "rgba(255, 0, 0, 0.1)", label: "Red (16-19)" }, // Red
      { min: 20, max: 24, color: "rgba(255, 165, 0, 0.1)", label: "Amber (20-23)" }, // Amber
      { min: 24, max: 33, color: "rgba(255, 255, 0, 0.1)", label: "Yellow (24-32)" }, // Yellow
      { min: 33, max: Infinity, color: "rgba(0, 255, 0, 0.1)", label: "Green (>32)" } // Green
    ];

    regions.forEach((region, index) => {
      const yMax = y.getPixelForValue(Math.min(region.max, y.max)); // Limit to chart max
      const yMin = Math.max(y.getPixelForValue(region.min), top);

      if (yMin <= bottom) {  // Only draw if region is visible within chart bounds
        ctx.save();
        ctx.fillStyle = region.color;
        ctx.fillRect(left, yMax, right - left, yMin - yMax);
        ctx.restore();

        // Draw border line below each region
        if (yMin !== top) {
          ctx.save();
          if(region.label==="Yellow (24-32)") ctx.strokeStyle = "rgba(246, 190, 0, 1)";
          else ctx.strokeStyle = regions[index]?.color.replace("0.1", "1") || "green"; // Color of the region above
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(left, yMin);
          ctx.lineTo(right, yMin);
          ctx.stroke();
          ctx.restore();
        }

        // Add label on the right side
        if(y.getPixelForValue(region.min) > top) {
          ctx.save();
          ctx.font = "12px Arial";
          if(region.label==="Yellow (24-32)") ctx.fillStyle = "rgba(246, 190, 0, 1)"; // Make text color solid
          else ctx.fillStyle = region.color.replace("0.1", "1"); // Make text color solid
          ctx.textAlign = "left";
          ctx.fillText(region.label, right + 10, (yMin + yMax) / 2); // Position in the middle of the region
          ctx.restore();
        }
      }
    });
  }
};

function App() {
  const currentMonth = dayjs().format('YYYY-MM');
  const oneYearLater = dayjs().add(1, 'year').format('YYYY-MM');

  const getLocalStorageItem = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  const [initialStartDate, setInitialStartDate] = useState(getLocalStorageItem('initialStartDate', currentMonth));
  const [startDate, setStartDate] = useState(getLocalStorageItem('startDate', currentMonth));
  const [endDate, setEndDate] = useState(getLocalStorageItem('endDate', oneYearLater));
  const [initialAwaiting, setInitialAwaiting] = useState(getLocalStorageItem('initialAwaiting', 0));
  const [initialCourse, setInitialCourse] = useState(getLocalStorageItem('initialCourse', 0));
  const [initialActive, setInitialActive] = useState(getLocalStorageItem('initialActive', 0));
  
  const [openInitialValues, setOpenInitialValues] = useState(true);
  const [openInjectValues, setOpenInjectValues] = useState(true);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [injects, setInjects] = useState(getLocalStorageItem('injects', []));
  const [injectMonth, setInjectMonth] = useState(currentMonth);
  const [injectType, setInjectType] = useState("Awaiting");
  const [injectAmount, setInjectAmount] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(false);

  useEffect(() => {
    const totalMonths = dayjs(endDate).diff(dayjs(initialStartDate), "month") + 1;
    const data = calculateProjections(
      totalMonths, 
      initialAwaiting, 
      initialCourse, 
      initialActive, 
      injects, 
      initialStartDate
    );

    const displayData = filterProjectionForDisplay(
      data, 
      initialStartDate, 
      startDate, 
      endDate
    );

    setChartData({
      labels: generateLabels(startDate, endDate),
      datasets: [
        {
          label: "Active TOs",
          data: displayData.active || [],
          backgroundColor: "rgba(0, 128, 0, 1)",
          order: 1,
        },
        {
          label: "On Course",
          data: displayData.course || [],
          backgroundColor: "rgba(54, 162, 235, 1)",
          order: 2,
        },
        {
          label: "Awaiting Course",
          data: displayData.awaitingCourse || [],
          backgroundColor: "rgba(255, 87, 51, 1)",
          order: 3,
        },
      ],
    });
  }, [initialStartDate, startDate, endDate, initialAwaiting, initialCourse, initialActive, injects]);

  // Save state to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('initialStartDate', JSON.stringify(initialStartDate));
  }, [initialStartDate]);

  useEffect(() => {
    localStorage.setItem('startDate', JSON.stringify(startDate));
  }, [startDate]);

  useEffect(() => {
    localStorage.setItem('endDate', JSON.stringify(endDate));
  }, [endDate]);

  useEffect(() => {
    localStorage.setItem('initialAwaiting', JSON.stringify(initialAwaiting));
  }, [initialAwaiting]);

  useEffect(() => {
    localStorage.setItem('initialCourse', JSON.stringify(initialCourse));
  }, [initialCourse]);

  useEffect(() => {
    localStorage.setItem('initialActive', JSON.stringify(initialActive));
  }, [initialActive]);

  useEffect(() => {
    localStorage.setItem('injects', JSON.stringify(injects));
  }, [injects]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleInitialStartDateChange = (e) => {
    setInitialStartDate(e.target.value);
  };

  const handleInjectAmountChange = (e) => {
    const value = e.target.value;
    setInjectAmount(value);
  
    if (!isNaN(value) && value.trim() !== "") {
      setIsValidAmount(true);
    } else {
      setIsValidAmount(false);
    }
  };  

  const handleAddInject = () => {
    if (isValidAmount) {
      setInjects([...injects, { month: injectMonth, type: injectType, amount: Number(injectAmount) }]);
      setInjectAmount("");
      setIsValidAmount(false);
    }
  };  

  const handleRemoveInject = (index) => {
    setInjects(injects.filter((_, i) => i !== index));
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} marginTop="5px">
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom>TO Manning Projection</Typography>
            <Bar
              data={chartData}
              options={{
                plugins: {
                  legend: { position: "top" },
                },
                scales: {
                  x: { stacked: true, grid: { display: false } }, // Remove X gridlines
                  y: { 
                    stacked: true, 
                    grid: { display: false }, // Remove Y gridlines
                    beginAtZero: true,
                    ticks: {
                      stepSize: 4, // Customize step size for clarity
                    }
                  },
                },
                layout: {
                  padding: { top: 20, right: 100 }
                }
              }}
              plugins={[regionPlugin]}  // Include the custom plugin
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Initial Values
              <IconButton onClick={() => setOpenInitialValues(!openInitialValues)}>
                {openInitialValues ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>
            <Collapse in={openInitialValues}>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  fullWidth
                  label="Initial Start Date"
                  type="month"
                  value={initialStartDate}
                  onChange={handleInitialStartDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 7 }}
                />
                <TextField
                  fullWidth
                  label="Display Range Start Date"
                  type="month"
                  value={startDate}
                  onChange={handleStartDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 7 }}
                />
                <TextField
                  fullWidth
                  label="Display Range End Date"
                  type="month"
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 7 }}
                />
                <TextField
                  fullWidth
                  label="Initial Awaiting Course"
                  type="number"
                  value={initialAwaiting}
                  onChange={(e) => setInitialAwaiting(Number(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Initial Course"
                  type="number"
                  value={initialCourse}
                  onChange={(e) => setInitialCourse(Number(e.target.value))}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label="Initial Active"
                  type="number"
                  value={initialActive}
                  onChange={(e) => setInitialActive(Number(e.target.value))}
                  sx={{ mb: 2 }}
                />
              </Box>
            </Collapse>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Inject Values
              <IconButton onClick={() => setOpenInjectValues(!openInjectValues)}>
                {openInjectValues ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>
            <Collapse in={openInjectValues}>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  fullWidth
                  label="Inject Month"
                  type="month"
                  value={injectMonth}
                  onChange={(e) => setInjectMonth(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 7 }}
                />
                <TextField
                  fullWidth
                  label="Inject Type"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  value={injectType}
                  onChange={(e) => setInjectType(e.target.value)}
                  sx={{ mb: 2 }}
                >
                  <option value="Awaiting">Awaiting Course</option>
                  <option value="Course">On Course</option>
                  <option value="Active">Active TOs</option>
                </TextField>
                <TextField
                  fullWidth
                  label="Inject Amount"
                  value={injectAmount}
                  onChange={handleInjectAmountChange}
                  sx={{ mb: 2 }}
                  helperText={isValidAmount ? "" : "Please enter a valid number"}
                />
                <Button
                  onClick={handleAddInject}
                  startIcon={<Add />}
                  variant="contained"
                  color="primary"
                  disabled={!isValidAmount}
                >
                  Add Inject
                </Button>
              </Box>

              <Box sx={{ mt: 2 }}>
                {injects.map((inject, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center', 
                      mb: 1, 
                      padding: 1, 
                      border: '1px solid #ccc', 
                      borderRadius: '4px',
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    <Typography>{(inject.type === 'Awaiting')?'Awaiting Course':(inject.type === 'Active')?'Active TO':'On Course' } | {inject.month} | {inject.amount}</Typography>
                    <IconButton onClick={() => handleRemoveInject(index)} color="error">
                      <Delete />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

const calculateProjections = (totalMonths, initialAwaiting, initialCourse, initialActive, injects, initialStartDate) => {
  const awaitingCourse = [];
  const course = [];
  const active = [];
  const peoplePerBatch = 4;

  let currentAwaiting = initialAwaiting;
  let currentCourse = initialCourse;
  let currentActive = initialActive;

  for (let i = 0; i < totalMonths; i++) {
    let currentAwaitingSnapshot = currentAwaiting;
    let currentCourseSnapshot = currentCourse;
    let currentActiveSnapshot = currentActive;
  
    const monthOffset = dayjs(initialStartDate).add(i, 'month').diff(dayjs(initialStartDate), 'month');
  
    injects.forEach((inject) => {
      const injectMonthOffset = dayjs(inject.month).diff(dayjs(initialStartDate), 'month');
      if (injectMonthOffset === i) {
        if (inject.type === 'Awaiting') currentAwaitingSnapshot += inject.amount;
        if (inject.type === 'Course') currentCourseSnapshot += inject.amount;
        if (inject.type === 'Active') currentActiveSnapshot += inject.amount;
      }
    });
  
    if (monthOffset % 3 === 0) {
      currentAwaitingSnapshot += peoplePerBatch;
    }
  
    if (i >= 1) {
      currentCourseSnapshot += awaitingCourse[i - 1] || 0;
      currentAwaitingSnapshot -= awaitingCourse[i - 1] || 0;
    }
  
    if (i >= 4) {
      currentActiveSnapshot += awaitingCourse[i - 5] || 0;
      currentCourseSnapshot -= awaitingCourse[i - 5] || 0;
    }
  
    if (i >= 13) {
      currentActiveSnapshot -= awaitingCourse[i - 18] || 0;
    }
  
    currentAwaiting = currentAwaitingSnapshot;
    currentCourse = currentCourseSnapshot;
    currentActive = currentActiveSnapshot;
  
    awaitingCourse.push(currentAwaiting);
    course.push(currentCourse);
    active.push(currentActive);
  }  

  return { awaitingCourse, course, active };
};

const filterProjectionForDisplay = (data, initialStartDate, startDate, endDate) => {
  const startOffset = dayjs(startDate).diff(dayjs(initialStartDate), "month");
  const endOffset = dayjs(endDate).diff(dayjs(initialStartDate), "month") + 1;

  return {
    awaitingCourse: data.awaitingCourse.slice(startOffset, endOffset),
    course: data.course.slice(startOffset, endOffset),
    active: data.active.slice(startOffset, endOffset),
  };
};

export default App;
